



































































































import Bop from "@/models/Bop";
import BopModel from "@/models/BopModel";
import OperationService from "@/services/OperationService";
import OperationGroupOperationService from "@/services/OperationGroupOperationService";
import {BopModelEnum, OperationType} from "@/utils/Enums";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import BopOperation from "@/models/BopOperation";
import OperationGroup from "@/models/OperationGroup";
import Constants from "@/utils/Constants"
import lodash from "lodash";
import OperationTypeService from "@/services/OperationTypeService";

type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};
const EPAISSEUR_CALORIFUGE = "EpaisseurColorifuge"
const DIAMETRE = "Diametre"
@Component({
  components: {
    BopSetupTable: () => import("@/components/Shared/Table/BopSetupTable.vue"),
    OperationFamilyFilterComponent: () => import("@/components/BopSetup/OperationFamilyFilterComponent.vue"),
  },
})
export default class OperationComponent extends Vue {
  @Prop({default: () => []})
  private fieldCoefficient!: Array<any>;
  @Prop({required: true})
  private operationGroup!: OperationGroup;
  @Prop({required: true})
  private columns!: Array<any>;
  @Prop({required: true})
  private bopModelId!: string;
  @Prop({required: true})
  private operationType!: number;
  @Prop()
  private isEditable!: boolean;
  @Prop()
  private operationGroupCoefficientFields!: Array<string>;
  @Prop()
  private typeTravaux!: string;
  @Prop()
  private siteId!: string;
  @Prop()
  private operationFamilyId!: string;
  @Prop()
  private operationFamilyOptions!: Array<any>;
  private refreshKey: string = "";
  private isLoaded = false;
  private isAddDisabled: boolean = false;
  private filteredOperations: Array<any> = [];
  private data: Data = {
    columns: [],
    rows: [],
  };
  private modifiedOperations: Array<any> = [];
  private isFullyLoaded = false;
  private rootFamilyId = "";
  /***
   * contains the coefficientOptions with all the informations to be used in calculus
   */
  private selectedGroupCoefficients: any = {};

  private get isPreGeneratedOperationsBop() {
    return [BopModelEnum.Echafaudage.toString()].indexOf(this.selectedBopModel?.key) !== -1 && this.operationType === OperationType.OperationPrincipal;
  }

  private get isFirstLevelDisabled(): boolean {
    return this.isBopTuyauteriePlastique && this.operations.findIndex(o => o.operationId) !== -1;
  }

  private get operations(): Array<any> {
    return this.operationGroup.operations ?? [];
  }

  private get operationGroupId() {
    return this.operationGroup.id;
  }

  private get operationGroupSelectedGroupCoefficients() {
    return this.operationGroup.selectedGroupCoefficients;
  }

  private get selectedBopModel(): BopModel {
    return this.$store.state.selectedBopModel
  }

  private get isOperationPrincipal(): boolean {
    return OperationType.OperationPrincipal == this.operationType;

  }

  private get isBopElectriciteInstrum(): boolean {
    return this.selectedBopModel && this.selectedBopModel.key === BopModelEnum.ElectriciteInstrumentation && this.isOperationPrincipal;
  }

  private get isBopEchafaudage(): boolean {
    return this.selectedBopModel && this.selectedBopModel.key === BopModelEnum.Echafaudage && this.isOperationPrincipal;
  }

  private get isBopTuyauteriePlastique(): boolean {
    return this.bop.bopModelKey === BopModelEnum.TuyauteriePlastique && this.isOperationPrincipal;
  }

  private autoCompleteOperations:Array<any> = [];

  private get operationOptions() {
    return this.$store.state.bopOperationOptions;
  }

  private get currentOperationOptions() {
    return this.operationOptions[this.operationGroup.id] ?? [];
  }

  private set currentOperationOptions(bopOperationOptions: any) {
    const config = {
      operationGroupId: this.operationGroup.id,
      bopOperationOptions
    };
    this.$store.commit('changeBopOperationOptions', config)
  }

  private get bop(): Bop {
    return this.$store.state.bop;
  }

  private get isNewBop(): boolean {
    return this.bop.id === Guid.createEmpty().toString();
  }

  private get canHaveOperationFamilyColumnfilter(): boolean {
    return this.selectedBopModel && this.selectedBopModel.key === BopModelEnum.GenieCivil && this.operationType === OperationType.OperationPrincipal;
  }

  private get language(): string {
    return this.$i18n.locale;
  }

  @Watch("modifiedOperations", {deep: true})
  onModifiedOperationsChange(modifiedOperations: Array<BopOperation>, oldModifiedOperations: Array<BopOperation>) {
    if (!this.isFullyLoaded) return;
    if (!modifiedOperations.length && !oldModifiedOperations?.length) return;
    let operations: Array<any> = [];
    const updatedOperations = JSON.parse(JSON.stringify(modifiedOperations));
    updatedOperations.forEach((modifiedOperation: BopOperation) => {
      if (!modifiedOperation.operation) {
        return;
      }
      // if(!modifiedOperation.coefficients){
      //   modifiedOperation.coefficients = JSON.parse(JSON.stringify(modifiedOperation.coefficientSelectedValues));
      // }
      for (let key in modifiedOperation.coefficientSelectedValues) {
        const csv = modifiedOperation.coefficientSelectedValues[key];
        if ((!csv.value || !csv.value.length) && !csv.coefficientOptionId) {
          modifiedOperation.coefficientSelectedValues[key].coefficientId = csv.coefficientId

        } else if (csv.value === Constants.DEFAULT_VALUE_EMPTY_OPTIONS) {
          csv.value = 0;
        }

      }
    });
    this.$emit("operationChange", {
      key: this.operationGroupId,
      value: updatedOperations,
    });
  }

  /**
   * ##################
   * methods
   * ##################
   */
  // event when the table is mounted
  private onTableMounted() {
    this.isFullyLoaded = true;
  }

  private async remove(data) {
    const rowIndex = data.rowIndex;
    const rowData = data.rowData;
    try {
      let response = await OperationGroupOperationService.deleteBopOperation(rowData.id);
      if (response.status === 200) {
        this.$notify({
          group: "global",
          type: "success",
          title: this.$t(
              "bopSetup.operations.deleteSuccessTitle"
          ).toString(),
          text: this.$t("bopSetup.operations.deleteSuccess").toString(),
        });
        const newOperations = this.operationGroup.operations.filter((op) => op.id !== rowData.id);
        this.operationGroup.operations = newOperations;
        this.modifiedOperations = newOperations;
        // const operationIndex = this.operationGroup.operations.findIndex(o=> o.id === rowData.id);
        // const operationGroupIndex = this.bop.operationPrincipalGroups.findIndex(og => og.id === this.operationGroupId);
        // this.modifiedOperations.splice(operationIndex,1);
        // this.$store.commit('removeBopOperationPrincipal',{operationGroupIndex,operationIndex});
        // this.operationGroup.operations.splice(operationIndex,1);
        (<any>this.$refs.table).removeRow(rowIndex);
        // this.bop.operationPrincipalGroups[operationGroupIndex].operations = newOperations;

      }
    } catch {
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t(
            "bopSetup.operations.deleteErrorTitle"
        ).toString(),
        text: this.$t("bopSetup.operations.deleteError").toString(),
        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS
      });
    }
  }

  private async onOperationPreAdd(selectedOperation) {
    const newOperation = new BopOperation();
    newOperation.operation = selectedOperation;
    newOperation.operationId = selectedOperation.id;

    this.addOperation(newOperation);
    // (<any>this.$refs.table).refresh();
  }

  private async onOperationFamilyChanged(data: { level: string, operationFamilyId: string }) {
    if (this.isBopTuyauteriePlastique && data.level === 'lvl1') {
      const operations = await this.loadTuyauteriePlastiqueOperation(data.operationFamilyId);
      this.currentOperationOptions = operations;
      this.autoCompleteOperations = operations;
      this.$forceUpdate();
    }
  }

  private async loadTuyauteriePlastiqueOperation(rootOperationFamilyId: string) {
    if (!rootOperationFamilyId) {
      this.currentOperationOptions = [];
      this.isAddDisabled = true;
      return;
    }
    const response = await OperationService.getTuyauteriePlastiqueBopOperations(this.language, rootOperationFamilyId);
    this.isAddDisabled = false;
    return response.data.map((el: any) => ({
      key: el,
      value: el.name,
    }));
  }

  private addTheFoundOperation(selectedOperation: any) {
    if (selectedOperation) {
      const newOperation = new BopOperation();
      newOperation.operation = selectedOperation.key;
      newOperation.operationId = selectedOperation.key.id;
      if (this.canHaveOperationFamilyColumnfilter) {
        newOperation.operationFamilyId = selectedOperation.key.operationFamilyId;
      }
      this.addOperation(newOperation);

    }
  }

  /**
   * Methos for operation group coefficients when any value changes reload the
   * operation select depending on the bopmodel type and then update the value of the coefficient for all the operations in the group
   */
  private async operationGroupValueChanged(field: any) {
    if (!this.isEditable) return;
    const bopModelKey = this.bop.bopModelKey;

    //  let selectedGroupCoefficients = {};

    const value = this.operationGroup.selectedGroupCoefficients[field.coeffKey];
    if (field.options) {
      this.selectedGroupCoefficients[field.coeffKey] = field.options.find(el => el.key.id == value)?.key;

    } else {
      this.selectedGroupCoefficients[field.coeffKey] = {value, coefficientId: field.id};
    }


    /***
     * Get the operations while handling the exceptions
     */
    const diametre = this.operationGroup.selectedGroupCoefficients[DIAMETRE];
    if (bopModelKey == BopModelEnum.TuyauteriePlastique) {
      const operations = (
          await OperationService.getBopOperationsWithExcpetion(
              this.bopModelId,
              this.language,
              this.operationFamilyId,
              diametre
          )
      ).data.map((el: any) => ({
        key: el,
        value: el.name,
      }));
      this.currentOperationOptions = operations;
    }
    //  const modifiedOperations = [... this.modifiedOperations];
    this.modifiedOperations.forEach((operation: BopOperation) => {
      operation.coefficients = {...operation.coefficients, ...this.selectedGroupCoefficients};
      if (field.options) {
        operation.coefficientSelectedValues[field.coeffKey].coefficientOptionId = this.selectedGroupCoefficients[field.coeffKey].id;
      } else {
        operation.coefficientSelectedValues[field.coeffKey].value = this.selectedGroupCoefficients[field.coeffKey].value;

      }
      // (<any>this.$refs.table).modify(operation);
    });
    this.refreshTable();
  }

  private refreshTable() {
    this.refreshKey = Guid.create().toString();
  }

  private addOperation(newOperation: any) {
    const selectedGroupCoefficients = lodash.cloneDeep(this.selectedGroupCoefficients);
    newOperation.coefficients = selectedGroupCoefficients;
    newOperation.coefficientSelectedValues = {};
    this.fieldCoefficient.forEach((coeff: any) => {
      const coeffKey = coeff.key;
      newOperation.coefficientSelectedValues[coeffKey] = {coefficientId: coeff.id};
      // its a selected coefficient option (type 2/3)
      if (selectedGroupCoefficients[coeffKey]) {
        if (selectedGroupCoefficients[coeffKey].id) {
          newOperation.coefficientSelectedValues[coeffKey].coefficientOptionId = selectedGroupCoefficients[coeffKey].id
        } else if (selectedGroupCoefficients[coeffKey].value) {
          //type 0
          newOperation.coefficientSelectedValues[coeffKey].value = selectedGroupCoefficients[coeffKey].value
        }
      }
    });
    if (this.isBopTuyauteriePlastique && this.operations.length > 0) {
      const diametreKey = "Diametre";
      const lastOperation = this.operations[this.operations.length - 1];
      newOperation.coefficients[diametreKey] = lodash.omit(lodash.cloneDeep(lastOperation.coefficients[diametreKey]), "id");
      newOperation.coefficientSelectedValues[diametreKey] = lodash.omit(lodash.cloneDeep(lastOperation.coefficientSelectedValues[diametreKey]), "id");
    }
    (<any>this.$refs.table).updatedRows.push(newOperation);
    (<any>this.$refs.table).modify(newOperation);
  }

  private add() {
    const newOperation = new BopOperation();
    this.addOperation(newOperation);
  }

  private async loadData() {
    this.operationGroup.selectedGroupCoefficients ??= {};
    if (this.operationGroup.selectedGroupCoefficients[EPAISSEUR_CALORIFUGE]) {
      this.operationGroup.selectedGroupCoefficients[EPAISSEUR_CALORIFUGE] = parseFloat(this.operationGroup.selectedGroupCoefficients[EPAISSEUR_CALORIFUGE]).toString();
    }
    if (this.operationGroupCoefficientFields) {
      this.operationGroupCoefficientFields.forEach((field: any) => {
        const coeffKey = field.coeffKey;
        const selectedValue = this.operationGroup.selectedGroupCoefficients[coeffKey];
        if (field.options) {
          this.selectedGroupCoefficients[coeffKey] = field.options.find(el => el.key.id == selectedValue)?.key;

        } else {
          this.selectedGroupCoefficients[coeffKey] = {value: selectedValue, coefficientId: field.id};
        }
      });
    }
    let operations: Array<any> = [];
    if ((
        this.selectedBopModel && this.selectedBopModel.key !== BopModelEnum.CalorifugageAppareil
        && this.selectedBopModel.key !== BopModelEnum.TuyauteriePlastique
    ) || this.operationType === OperationType.OperationDiverse) {
      operations = (
          await OperationService.getBopOperations(
              this.bopModelId,
              this.operationType,
              this.language,
              this.typeTravaux,
              this.siteId,
              this.operationFamilyId
          )
      ).data.map((el: any) => ({
        key: el,
        value: el.name,
      }));

    } else if (this.selectedBopModel.key === BopModelEnum.TuyauteriePlastique) {
      const firsValidOperation = this.operations.find(o => o.operationId);
      if (firsValidOperation) {
        const selectedOperationFamilyId = firsValidOperation.operationFamilyId;

        const rootFamily = (await OperationTypeService.getOperationFamilyRootFromDescendent(selectedOperationFamilyId)).data;
        if (rootFamily) {
          operations = await this.loadTuyauteriePlastiqueOperation(rootFamily.id);
          this.rootFamilyId = rootFamily.id;
        }
      }
    }
    if(this.selectedBopModel.key === BopModelEnum.CalorifugageAppareil && this.operationType === OperationType.OperationPrincipal){
      this.autoCompleteOperations = this.currentOperationOptions;
    }else
    if (operations.length) {
      this.currentOperationOptions = operations;
      this.autoCompleteOperations = operations;
      this.refreshKey = Guid.create().toString();
    }
    this.currentOperationOptions ??= [];
    let columns: Array<any> = [
      {
        name: "disabledDynamicInput",
        title: this.$t("bopSetup.operationCode").toString(),
        formula: "operation.code",
      }
    ];
    if (this.canHaveOperationFamilyColumnfilter) {
      columns.push(
          {
            name: "bopOperationFamilyDropdown",
            title: this.$t("bopSetup.operationFamily").toString(),
            property: "operationFamilyId",
            // display: "operationName",
            options: this.operationFamilyOptions,
            operationGroupId: this.operationGroup.id,
            valueField: "id",
            textField: "name",
            isNotEditable: !this.isEditable
          });
    }

    columns.push(
        {
          name: "bopOperationDropDown",
          title: this.$t("bopSetup.operationName").toString(),
          property: "operationId",
          // display: "operationName",
          operationGroupId: this.operationGroup.id,
          valueField: "key.id",
          textField: "value",
          dataContainer: "operation",
          isNotEditable: !this.isEditable || this.isBopEchafaudage
        });
    const propsColumns = this.columns.map((c: any) => (c.name === 'bopCoefficientDropDown' ? {
      ...c,
      operationGroupId: this.operationGroupId
    } : c));
    columns = columns.concat(propsColumns);
    if (this.isEditable) {
      columns.push({
        name: "actions",
        title: "",
        removeButton: !this.isBopEchafaudage,
        editButton: false,
        duplicateButton: false,
      });
    }
    this.data.columns = columns;
  }

  /**
   * ##########
   * Hooks
   * ##########
   */
  private created() {

    this.loadData().then(() => {

    })
  }

  private mounted() {
    this.$nextTick().then(() => {
      this.$store.commit('changeHasBeenModified', false);
    })

  }

  private onTotalPointsChanged(totalPoints: any) {
    this.$emit("totalPointsChanged", {
      key: this.operationGroupId,
      value: totalPoints,
    });
  }

  @Watch('isFullyLoaded')
  private onIsFullyLoadedChange() {
    if (this.isFullyLoaded) {
      this.onModifiedOperationsChange(this.modifiedOperations, []);
      this.$emit('fully-loaded');
    }
  }
}
